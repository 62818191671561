// @generated by protoc-gen-connect-query v2.0.1 with parameter "target=ts"
// @generated from file fijoy/v1/transaction.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */

import { TransactionService } from "./transaction_pb";

/**
 * @generated from rpc fijoy.v1.TransactionService.CreateTransaction
 */
export const createTransaction = TransactionService.method.createTransaction;

/**
 * @generated from rpc fijoy.v1.TransactionService.GetTransaction
 */
export const getTransaction = TransactionService.method.getTransaction;

/**
 * @generated from rpc fijoy.v1.TransactionService.GetTransactionsByAccount
 */
export const getTransactionsByAccount = TransactionService.method.getTransactionsByAccount;

/**
 * @generated from rpc fijoy.v1.TransactionService.GetTransactions
 */
export const getTransactions = TransactionService.method.getTransactions;

/**
 * @generated from rpc fijoy.v1.TransactionService.UpdateTransaction
 */
export const updateTransaction = TransactionService.method.updateTransaction;

/**
 * @generated from rpc fijoy.v1.TransactionService.DeleteTransaction
 */
export const deleteTransaction = TransactionService.method.deleteTransaction;
