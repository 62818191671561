// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file fijoy/v1/user.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../buf/validate/validate_pb";
import type { EmptySchema, Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_empty, file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file fijoy/v1/user.proto.
 */
export const file_fijoy_v1_user: GenFile = /*@__PURE__*/
  fileDesc("ChNmaWpveS92MS91c2VyLnByb3RvEghmaWpveS52MSJrCgRVc2VyEhMKAmlkGAEgASgJQge6SARyAhABEhYKBWVtYWlsGAIgASgJQge6SARyAhABEjYKCmNyZWF0ZWRfYXQYAyABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wQga6SAPIAQEyRQoLVXNlclNlcnZpY2USNgoHR2V0VXNlchIWLmdvb2dsZS5wcm90b2J1Zi5FbXB0eRoOLmZpam95LnYxLlVzZXIiA5ACAUJ4Cgxjb20uZmlqb3kudjFCCVVzZXJQcm90b1ABWhxmaWpveS9wcm90by9maWpveS92MTtmaWpveXYxogIDRlhYqgIIRmlqb3kuVjHKAghGaWpveVxWMeICFEZpam95XFYxXEdQQk1ldGFkYXRh6gIJRmlqb3k6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_google_protobuf_empty, file_google_protobuf_timestamp]);

/**
 * @generated from message fijoy.v1.User
 */
export type User = Message<"fijoy.v1.User"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: string email = 2;
   */
  email: string;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 3;
   */
  createdAt?: Timestamp;
};

/**
 * Describes the message fijoy.v1.User.
 * Use `create(UserSchema)` to create a new message.
 */
export const UserSchema: GenMessage<User> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_user, 0);

/**
 * @generated from service fijoy.v1.UserService
 */
export const UserService: GenService<{
  /**
   * @generated from rpc fijoy.v1.UserService.GetUser
   */
  getUser: {
    methodKind: "unary";
    input: typeof EmptySchema;
    output: typeof UserSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_fijoy_v1_user, 0);

