// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file fijoy/v1/transaction.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../buf/validate/validate_pb";
import type { Account } from "./account_pb";
import { file_fijoy_v1_account } from "./account_pb";
import type { EmptySchema, Timestamp } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_empty, file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file fijoy/v1/transaction.proto.
 */
export const file_fijoy_v1_transaction: GenFile = /*@__PURE__*/
  fileDesc("ChpmaWpveS92MS90cmFuc2FjdGlvbi5wcm90bxIIZmlqb3kudjEi3QEKC1RyYW5zYWN0aW9uEhMKAmlkGAEgASgJQge6SARyAhABEisKB2FjY291bnQYAiABKAsyES5maWpveS52MS5BY2NvdW50Qge6SARyAhABEg4KBmFtb3VudBgDIAEoCRIMCgRub3RlGAQgASgJEjYKCmNyZWF0ZWRfYXQYBSABKAsyGi5nb29nbGUucHJvdG9idWYuVGltZXN0YW1wQga6SAPIAQESNgoKdXBkYXRlZF9hdBgGIAEoCzIaLmdvb2dsZS5wcm90b2J1Zi5UaW1lc3RhbXBCBrpIA8gBASI/Cg9UcmFuc2FjdGlvbkxpc3QSLAoFaXRlbXMYASADKAsyFS5maWpveS52MS5UcmFuc2FjdGlvbkIGukgDyAEBIlUKGENyZWF0ZVRyYW5zYWN0aW9uUmVxdWVzdBIbCgphY2NvdW50X2lkGAEgASgJQge6SARyAhABEg4KBmFtb3VudBgCIAEoCRIMCgRub3RlGAMgASgJIiwKFUdldFRyYW5zYWN0aW9uUmVxdWVzdBITCgJpZBgBIAEoCUIHukgEcgIQASItChZHZXRUcmFuc2FjdGlvbnNSZXF1ZXN0EhMKAmlkGAEgASgJQge6SARyAhABIj4KH0dldFRyYW5zYWN0aW9uc0J5QWNjb3VudFJlcXVlc3QSGwoKYWNjb3VudF9pZBgBIAEoCUIHukgEcgIQASJrChhVcGRhdGVUcmFuc2FjdGlvblJlcXVlc3QSEwoCaWQYASABKAlCB7pIBHICEAESEwoGYW1vdW50GAIgASgJSACIAQESEQoEbm90ZRgDIAEoCUgBiAEBQgkKB19hbW91bnRCBwoFX25vdGUiLwoYRGVsZXRlVHJhbnNhY3Rpb25SZXF1ZXN0EhMKAmlkGAEgASgJQge6SARyAhABMoYEChJUcmFuc2FjdGlvblNlcnZpY2USTgoRQ3JlYXRlVHJhbnNhY3Rpb24SIi5maWpveS52MS5DcmVhdGVUcmFuc2FjdGlvblJlcXVlc3QaFS5maWpveS52MS5UcmFuc2FjdGlvbhJNCg5HZXRUcmFuc2FjdGlvbhIfLmZpam95LnYxLkdldFRyYW5zYWN0aW9uUmVxdWVzdBoVLmZpam95LnYxLlRyYW5zYWN0aW9uIgOQAgESZQoYR2V0VHJhbnNhY3Rpb25zQnlBY2NvdW50EikuZmlqb3kudjEuR2V0VHJhbnNhY3Rpb25zQnlBY2NvdW50UmVxdWVzdBoZLmZpam95LnYxLlRyYW5zYWN0aW9uTGlzdCIDkAIBEkkKD0dldFRyYW5zYWN0aW9ucxIWLmdvb2dsZS5wcm90b2J1Zi5FbXB0eRoZLmZpam95LnYxLlRyYW5zYWN0aW9uTGlzdCIDkAIBEk4KEVVwZGF0ZVRyYW5zYWN0aW9uEiIuZmlqb3kudjEuVXBkYXRlVHJhbnNhY3Rpb25SZXF1ZXN0GhUuZmlqb3kudjEuVHJhbnNhY3Rpb24STwoRRGVsZXRlVHJhbnNhY3Rpb24SIi5maWpveS52MS5EZWxldGVUcmFuc2FjdGlvblJlcXVlc3QaFi5nb29nbGUucHJvdG9idWYuRW1wdHlCfwoMY29tLmZpam95LnYxQhBUcmFuc2FjdGlvblByb3RvUAFaHGZpam95L3Byb3RvL2Zpam95L3YxO2Zpam95djGiAgNGWFiqAghGaWpveS5WMcoCCEZpam95XFYx4gIURmlqb3lcVjFcR1BCTWV0YWRhdGHqAglGaWpveTo6VjFiBnByb3RvMw", [file_buf_validate_validate, file_fijoy_v1_account, file_google_protobuf_empty, file_google_protobuf_timestamp]);

/**
 * @generated from message fijoy.v1.Transaction
 */
export type Transaction = Message<"fijoy.v1.Transaction"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: fijoy.v1.Account account = 2;
   */
  account?: Account;

  /**
   * @generated from field: string amount = 3;
   */
  amount: string;

  /**
   * @generated from field: string note = 4;
   */
  note: string;

  /**
   * @generated from field: google.protobuf.Timestamp created_at = 5;
   */
  createdAt?: Timestamp;

  /**
   * @generated from field: google.protobuf.Timestamp updated_at = 6;
   */
  updatedAt?: Timestamp;
};

/**
 * Describes the message fijoy.v1.Transaction.
 * Use `create(TransactionSchema)` to create a new message.
 */
export const TransactionSchema: GenMessage<Transaction> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 0);

/**
 * @generated from message fijoy.v1.TransactionList
 */
export type TransactionList = Message<"fijoy.v1.TransactionList"> & {
  /**
   * @generated from field: repeated fijoy.v1.Transaction items = 1;
   */
  items: Transaction[];
};

/**
 * Describes the message fijoy.v1.TransactionList.
 * Use `create(TransactionListSchema)` to create a new message.
 */
export const TransactionListSchema: GenMessage<TransactionList> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 1);

/**
 * @generated from message fijoy.v1.CreateTransactionRequest
 */
export type CreateTransactionRequest = Message<"fijoy.v1.CreateTransactionRequest"> & {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId: string;

  /**
   * @generated from field: string amount = 2;
   */
  amount: string;

  /**
   * @generated from field: string note = 3;
   */
  note: string;
};

/**
 * Describes the message fijoy.v1.CreateTransactionRequest.
 * Use `create(CreateTransactionRequestSchema)` to create a new message.
 */
export const CreateTransactionRequestSchema: GenMessage<CreateTransactionRequest> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 2);

/**
 * @generated from message fijoy.v1.GetTransactionRequest
 */
export type GetTransactionRequest = Message<"fijoy.v1.GetTransactionRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;
};

/**
 * Describes the message fijoy.v1.GetTransactionRequest.
 * Use `create(GetTransactionRequestSchema)` to create a new message.
 */
export const GetTransactionRequestSchema: GenMessage<GetTransactionRequest> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 3);

/**
 * @generated from message fijoy.v1.GetTransactionsRequest
 */
export type GetTransactionsRequest = Message<"fijoy.v1.GetTransactionsRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;
};

/**
 * Describes the message fijoy.v1.GetTransactionsRequest.
 * Use `create(GetTransactionsRequestSchema)` to create a new message.
 */
export const GetTransactionsRequestSchema: GenMessage<GetTransactionsRequest> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 4);

/**
 * @generated from message fijoy.v1.GetTransactionsByAccountRequest
 */
export type GetTransactionsByAccountRequest = Message<"fijoy.v1.GetTransactionsByAccountRequest"> & {
  /**
   * @generated from field: string account_id = 1;
   */
  accountId: string;
};

/**
 * Describes the message fijoy.v1.GetTransactionsByAccountRequest.
 * Use `create(GetTransactionsByAccountRequestSchema)` to create a new message.
 */
export const GetTransactionsByAccountRequestSchema: GenMessage<GetTransactionsByAccountRequest> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 5);

/**
 * @generated from message fijoy.v1.UpdateTransactionRequest
 */
export type UpdateTransactionRequest = Message<"fijoy.v1.UpdateTransactionRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;

  /**
   * @generated from field: optional string amount = 2;
   */
  amount?: string;

  /**
   * @generated from field: optional string note = 3;
   */
  note?: string;
};

/**
 * Describes the message fijoy.v1.UpdateTransactionRequest.
 * Use `create(UpdateTransactionRequestSchema)` to create a new message.
 */
export const UpdateTransactionRequestSchema: GenMessage<UpdateTransactionRequest> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 6);

/**
 * @generated from message fijoy.v1.DeleteTransactionRequest
 */
export type DeleteTransactionRequest = Message<"fijoy.v1.DeleteTransactionRequest"> & {
  /**
   * @generated from field: string id = 1;
   */
  id: string;
};

/**
 * Describes the message fijoy.v1.DeleteTransactionRequest.
 * Use `create(DeleteTransactionRequestSchema)` to create a new message.
 */
export const DeleteTransactionRequestSchema: GenMessage<DeleteTransactionRequest> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_transaction, 7);

/**
 * @generated from service fijoy.v1.TransactionService
 */
export const TransactionService: GenService<{
  /**
   * @generated from rpc fijoy.v1.TransactionService.CreateTransaction
   */
  createTransaction: {
    methodKind: "unary";
    input: typeof CreateTransactionRequestSchema;
    output: typeof TransactionSchema;
  },
  /**
   * @generated from rpc fijoy.v1.TransactionService.GetTransaction
   */
  getTransaction: {
    methodKind: "unary";
    input: typeof GetTransactionRequestSchema;
    output: typeof TransactionSchema;
  },
  /**
   * @generated from rpc fijoy.v1.TransactionService.GetTransactionsByAccount
   */
  getTransactionsByAccount: {
    methodKind: "unary";
    input: typeof GetTransactionsByAccountRequestSchema;
    output: typeof TransactionListSchema;
  },
  /**
   * @generated from rpc fijoy.v1.TransactionService.GetTransactions
   */
  getTransactions: {
    methodKind: "unary";
    input: typeof EmptySchema;
    output: typeof TransactionListSchema;
  },
  /**
   * @generated from rpc fijoy.v1.TransactionService.UpdateTransaction
   */
  updateTransaction: {
    methodKind: "unary";
    input: typeof UpdateTransactionRequestSchema;
    output: typeof TransactionSchema;
  },
  /**
   * @generated from rpc fijoy.v1.TransactionService.DeleteTransaction
   */
  deleteTransaction: {
    methodKind: "unary";
    input: typeof DeleteTransactionRequestSchema;
    output: typeof EmptySchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_fijoy_v1_transaction, 0);

