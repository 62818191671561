// @generated by protoc-gen-connect-query v2.0.1 with parameter "target=ts"
// @generated from file fijoy/v1/profile.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */

import { ProfileService } from "./profile_pb";

/**
 * @generated from rpc fijoy.v1.ProfileService.CreateProfile
 */
export const createProfile = ProfileService.method.createProfile;

/**
 * @generated from rpc fijoy.v1.ProfileService.GetProfile
 */
export const getProfile = ProfileService.method.getProfile;

/**
 * @generated from rpc fijoy.v1.ProfileService.UpdateProfile
 */
export const updateProfile = ProfileService.method.updateProfile;

/**
 * @generated from rpc fijoy.v1.ProfileService.DeleteProfile
 */
export const deleteProfile = ProfileService.method.deleteProfile;
