// @generated by protoc-gen-connect-query v2.0.1 with parameter "target=ts"
// @generated from file fijoy/v1/currency.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */

import { CurrencyService } from "./currency_pb";

/**
 * @generated from rpc fijoy.v1.CurrencyService.GetCurrencies
 */
export const getCurrencies = CurrencyService.method.getCurrencies;
