// @generated by protoc-gen-es v2.2.3 with parameter "target=ts"
// @generated from file fijoy/v1/currency.proto (package fijoy.v1, syntax proto3)
/* eslint-disable */

import type { GenFile, GenMessage, GenService } from "@bufbuild/protobuf/codegenv1";
import { fileDesc, messageDesc, serviceDesc } from "@bufbuild/protobuf/codegenv1";
import { file_buf_validate_validate } from "../../buf/validate/validate_pb";
import type { EmptySchema } from "@bufbuild/protobuf/wkt";
import { file_google_protobuf_empty, file_google_protobuf_timestamp } from "@bufbuild/protobuf/wkt";
import type { Message } from "@bufbuild/protobuf";

/**
 * Describes the file fijoy/v1/currency.proto.
 */
export const file_fijoy_v1_currency: GenFile = /*@__PURE__*/
  fileDesc("ChdmaWpveS92MS9jdXJyZW5jeS5wcm90bxIIZmlqb3kudjEiOgoIQ3VycmVuY3kSFQoEY29kZRgBIAEoCUIHukgEcgIQARIXCgZsb2NhbGUYAiABKAlCB7pIBHICEAEiOQoMQ3VycmVuY3lMaXN0EikKBWl0ZW1zGAEgAygLMhIuZmlqb3kudjEuQ3VycmVuY3lCBrpIA8gBATJXCg9DdXJyZW5jeVNlcnZpY2USRAoNR2V0Q3VycmVuY2llcxIWLmdvb2dsZS5wcm90b2J1Zi5FbXB0eRoWLmZpam95LnYxLkN1cnJlbmN5TGlzdCIDkAIBQnwKDGNvbS5maWpveS52MUINQ3VycmVuY3lQcm90b1ABWhxmaWpveS9wcm90by9maWpveS92MTtmaWpveXYxogIDRlhYqgIIRmlqb3kuVjHKAghGaWpveVxWMeICFEZpam95XFYxXEdQQk1ldGFkYXRh6gIJRmlqb3k6OlYxYgZwcm90bzM", [file_buf_validate_validate, file_google_protobuf_empty, file_google_protobuf_timestamp]);

/**
 * @generated from message fijoy.v1.Currency
 */
export type Currency = Message<"fijoy.v1.Currency"> & {
  /**
   * @generated from field: string code = 1;
   */
  code: string;

  /**
   * @generated from field: string locale = 2;
   */
  locale: string;
};

/**
 * Describes the message fijoy.v1.Currency.
 * Use `create(CurrencySchema)` to create a new message.
 */
export const CurrencySchema: GenMessage<Currency> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_currency, 0);

/**
 * @generated from message fijoy.v1.CurrencyList
 */
export type CurrencyList = Message<"fijoy.v1.CurrencyList"> & {
  /**
   * @generated from field: repeated fijoy.v1.Currency items = 1;
   */
  items: Currency[];
};

/**
 * Describes the message fijoy.v1.CurrencyList.
 * Use `create(CurrencyListSchema)` to create a new message.
 */
export const CurrencyListSchema: GenMessage<CurrencyList> = /*@__PURE__*/
  messageDesc(file_fijoy_v1_currency, 1);

/**
 * @generated from service fijoy.v1.CurrencyService
 */
export const CurrencyService: GenService<{
  /**
   * @generated from rpc fijoy.v1.CurrencyService.GetCurrencies
   */
  getCurrencies: {
    methodKind: "unary";
    input: typeof EmptySchema;
    output: typeof CurrencyListSchema;
  },
}> = /*@__PURE__*/
  serviceDesc(file_fijoy_v1_currency, 0);

